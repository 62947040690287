export class PublicApiService {
    constructor($cookies, $http, $q, apiService, $location) {
        'ngInject';

        this.$cookies = $cookies;
        this.$http = $http;
        this.$q = $q;
        this.apiService = apiService;

        this.activeProgrammesPromise = undefined;

        this.emptyProgrammes = [];

        this.searchParams = $location.search();
    }

    /**
     * @returns patientId and bg when provided through search params
     */
    getPatientDetailsFromSearchParams() {
        const patientUuid = this.searchParams.patient_id || null;
        const bg = this.searchParams.bg || null;

        return {patientUuid, bg}
    }

    /**
     * @returns true if patientId and bg is provided in search params
     */
    isUsingPatientDetails() {
        const {patientUuid, bg} = this.getPatientDetailsFromSearchParams();
        if (patientUuid && bg) {
            return true;
        }
        return false;
    }

    /**
     * @returns {string|null} 8-digit public identifier or  <code>null</code>
     */
    getPatientIdentifier() {
        let patientIdentifier = this.$cookies.get('patientIdentifier');
        if (angular.isDefined(patientIdentifier)) {
            return JSON.parse(patientIdentifier);
        }
        return {
            patientUuid: null, bg: null, patientPublicId: null
        }
    }

    /**
     * Ether public profile id or UUID
     * @param patientId patient uuid
     * @param patientPublicId patient public id
     */
    setPatientIdentifier(patientPublicId) {
        const {patientUuid, bg} = this.getPatientDetailsFromSearchParams();
        let expiringDate = new Date();
        // Cookie expires in about 1 month
        expiringDate.setMonth(expiringDate.getMonth() + 1);
        this.$cookies.put('patientIdentifier', JSON.stringify({patientUuid, bg, patientPublicId}), {
            expires: expiringDate
        });
    }

    getPublicApiUrl() {
        let {patientUuid, bg, patientPublicId} = this.getPatientIdentifier();

        let publicApiUrl = null;
        if (patientPublicId){
            publicApiUrl = this.apiService.getPublicApiUrl(patientPublicId) + 'landing-page.json/' + patientPublicId;
        } else if (patientUuid && bg){
            publicApiUrl = this.apiService.getPublicApiUrlForBG(bg) + 'landing-page.json?patientUserId=' + patientUuid;
        }

        return publicApiUrl;
    }

    getPublicApiUrlForTracking() {
        let {patientUuid, bg, patientPublicId} = this.getPatientIdentifier();

        let publicApiUrl = null;
        if (patientPublicId){
            publicApiUrl = this.apiService.getPublicApiUrl(patientPublicId);
        } else if (patientUuid && bg){
            publicApiUrl = this.apiService.getPublicApiUrlForBG(bg);
        }

        return publicApiUrl;
    }

    getActiveProgrammes(forceReload) {
        if (forceReload) {
            delete this.activeProgrammesPromise;
        } else if (angular.isDefined(this.activeProgrammesPromise)) {
            return this.activeProgrammesPromise;
        }

        let {patientUuid, bg, patientPublicId} = this.getPatientIdentifier();

        if (patientPublicId === null && (patientUuid === null || bg === null)) {
            return this.$q.resolve(this.emptyProgrammes);
        }

        this.activeProgrammesPromise = this.$http({
            method: 'GET',
            url: this.getPublicApiUrl()
        }).then(response => {
            let activeProgrammes = response.data;
            return activeProgrammes;
        });
        /*
         * No error handling here. Error handling is done in the landing page controller, and afterwards
         * this should either always be fine or the landing page didn't let the user proceed.
         */

        return this.activeProgrammesPromise;
    }

}