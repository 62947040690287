import './programmeEntry.scss';

// Dimension based on assets/img/programme_generic_illustration1.svg
const GENERIC_IMAGE_WIDTH = 341;
const GENERIC_IMAGE_HEIGHT = 154;

export function ProgrammeEntryDirective() {
    'ngInject';
    return {
        restrict: 'E',
        templateUrl: 'overviewPage/programmeEntry/programmeEntry.html',
        scope: {
            index: '<',
            programmeId: '<',
            optionSet: '<'
        },
        controller: ProgrammeEntryController,
        controllerAs: 'ctrl',
        bindToController: true,
    }
}

class ProgrammeEntryController {

    constructor($scope, $sce, $state, marked, contentfulHelper, textDirection) {
        'ngInject';

        this.$state = $state;
        this.$sce = $sce;
        this.marked = marked;
        this.programmeLoaded = false;
        this.contentfulHelper = contentfulHelper;
        this.textDirection = textDirection;
    }
    
    $onInit() {
        let contentfulManager = this.contentfulHelper.getContentfulManager(this.optionSet);

        contentfulManager.getProgramme(this.programmeId)
            .then(programme => {
                this.programme = programme;
                this.programmeLoaded = true;

                if (angular.isObject(this.programme.fields)) {
                    this.modules = this.programme.fields.modules;

                    if (angular.isUndefined(this.programme.fields.bodyIsParsed) &&
                        angular.isDefined(this.programme.fields.body)) {
                        this.programme.fields.body = this.$sce.trustAsHtml(this.marked(this.programme.fields.body));
                        this.programme.fields.bodyIsParsed = true;
                    }

                    this.textDirection.adjustLayout(this.programme.fields.title);
                }
            });
    }

    getImage() {
        if (angular.isDefined(this.programme) && this.programme.fields.overviewImage) {
            // Specify width/height to have same ratio as generic images:
            // https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping/specify-width-&-height
            return this.programme.fields.overviewImage.fields.file.url + '?fit=fill&w='+ GENERIC_IMAGE_WIDTH +'&h=' + GENERIC_IMAGE_HEIGHT;
        }

        // Generic image when no overviewImage
        if (this.index % 2 === 0) {
            return 'assets/img/programme_generic_illustration1.svg';
        } else {
            return 'assets/img/programme_generic_illustration2.svg';
        }
    }

    openProgramme() {
        this.$state.go('programmePage', {programmeId: this.programmeId});
    }

    getProgramCompletionPercentage() {
        return this.contentfulHelper.getProgramCompletionPercentage(this.modules);
    }

}
