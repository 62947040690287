
/**
 * Created by raoulzander on 21.07.17.
 */
export function markdownConfig(markedProvider) {
    'ngInject';

    markedProvider.setOptions({
        gfm: true,
        sanitize: true,
    });

    markedProvider.setRenderer({
        link: function (href, title, text) {
            return '<a href="' + href + '"' + (title ? ' title="' + title + '"' : '') + ' target="_blank">' + text + '</a>';
        },
        image: function (href, title, text) {
            return '<cms-photo-swipe image-src="' + href + '" image-alt="' + text + '"></cms-photo-swipe>';
        },
    });
}