/**
 * Created by raoulzander on 31.05.17.
 */
import './contentPage.scss';

/* global angular */

export class ContentPageController {
    constructor($scope, $window, $document, $state, $mdMedia, contentfulHelper, tracking, renderConfig, textDirection, publicApiService) {
        'ngInject';

        this.$state = $state;
        this.$window = $window;
        this.contentfulHelper = contentfulHelper;
        this.$scope = $scope;
        this.publicApiService = publicApiService;

        $scope.$mdMedia = $mdMedia;

        this.programmeLoaded = false;
        this.programmeId = $state.params.programmeId;
        this.optionSet = $state.params.optionSet;
        this.omitHierarchy = $state.params.omitHierarchy;

        if(this.publicApiService.isUsingPatientDetails()) {
            publicApiService.setPatientIdentifier(null);
        }

        let contentfulManager = contentfulHelper.getContentfulManager(this.optionSet);

        if ($state.is('modulePage')) {
            this.moduleId = $state.params.moduleId;

            contentfulManager.getModule(this.programmeId, this.moduleId, this.omitHierarchy)
                .then(results => {
                    this.programme = results.programme;
                    this.programmeLoaded = true;

                    if (!angular.isObject(results.module.fields)) {
                        return;
                    }

                    // Redirect to first content page, if available
                    if (contentfulHelper.hasRlos(results.module)) {
                        let contentEntry = results.module.fields.rlos[0];
                        $state.go('contentPage', {
                            programmeId: this.programmeId,
                            contentId: contentEntry.sys.id,
                            optionSet: this.optionSet,
                            omitHierarchy: this.omitHierarchy,
                        });
                    } else {
                        this.entry = results.module;
                        this.direction = textDirection.adjustLayout(this.entry.fields.title);

                        $scope.content = this.entry.fields;
                        $state.go('.module');
                        tracking.trackView($scope, this.moduleId, this.programme.sys.id);
                    }
                });
        } else {
            // It's a content page
            this.contentId = $state.params.contentId;
            $scope.navigateToNextEntry = () => this.navigateToNextEntry();

            contentfulManager.getContent(this.programmeId, this.contentId, this.omitHierarchy)
                .then(results => {
                    this.programme = results.programme;
                    this.module = results.module;
                    this.entry = results.content;
                    this.programmeLoaded = true;

                    this.selectedEntryId = this.entry.sys.id;

                    if (!angular.isObject(this.entry.fields)) {
                        return;
                    }

                    this.direction = textDirection.adjustLayout(this.entry.fields.title);
                    $scope.content = this.entry.fields;
                    $scope.contentId = this.selectedEntryId;

                    let programmeId;
                    if (this.programme) {
                        programmeId = this.programme.sys.id;
                        $scope.programmeId = programmeId;
                        $scope.programme = this.programme;
                        this.currentModuleIndex = this.programme.fields.modules.indexOf(this.module);
                    }

                    if (contentfulHelper.hasRlos(this.module)) {
                        this.currentPageIndex = this.module.fields.rlos.indexOf(this.entry);
                        if (this.currentPageIndex > 0) {
                            this.previousEntryId = this.module.fields.rlos[this.currentPageIndex - 1].sys.id;
                            this.previousNavigationTarget = 'content';
                        } else {
                            if (renderConfig.endOfModuleBehavior === renderConfig.END_OF_MODULE_BEHAVIORS.goToNextModule) {
                                if (this.currentModuleIndex > 0) {
                                    let previousModule = this.programme.fields.modules[this.currentModuleIndex - 1];
                                    let lastEntryOfModule = previousModule.fields.rlos[previousModule.fields.rlos.length - 1];
                                    this.previousEntryId = lastEntryOfModule.sys.id;
                                }
                                this.previousNavigationTarget = 'content';
                            } else {
                                this.previousEntryId = programmeId;
                                this.previousNavigationTarget = 'programme';
                            }
                        }

                        if (this.currentPageIndex < this.module.fields.rlos.length - 1) {
                            let nextEntry = this.module.fields.rlos[this.currentPageIndex + 1];
                            this.nextEntryId = nextEntry.sys.id;
                            // Function to keep the reference instead of a copied primitive
                            this.nextEntryBlocked = () => nextEntry.isBlocked;
                            this.nextNavigationTarget = 'content';
                        } else {
                            let foundNextEntry = false;
                            if (contentfulHelper.hasModules(this.programme)) {
                                if (this.currentModuleIndex < this.programme.fields.modules.length - 1) {
                                    let nextModule = this.programme.fields.modules[this.currentModuleIndex + 1];
                                    if (contentfulHelper.hasRlos(nextModule)) {
                                        const currentContent = this.module.fields.rlos[this.currentPageIndex];
                                        let firstEntryOfModule = nextModule.fields.rlos[0];
                                        this.nextEntryId = firstEntryOfModule.sys.id;
                                        // Function to keep the reference instead of a copied primitive
                                        this.nextEntryBlocked = () => nextModule.isBlocked && currentContent.quizBlocksNextEntry;
                                        foundNextEntry = true;
                                    }
                                }
                                this.nextNavigationTarget = 'content';
                            }

                            if (!foundNextEntry || renderConfig.endOfModuleBehavior === renderConfig.END_OF_MODULE_BEHAVIORS.goToProgramme) {
                                this.nextEntryId = programmeId;
                                this.nextNavigationTarget = 'programme';
                            }
                        }

                        // Let the current page index be 1-based for displaying purposes.
                        this.currentPageIndex++;
                    }

                    $state.go('.' + this.getEntryType(this.entry));
                    tracking.trackView($scope, this.contentId, programmeId);
                });
        }
    }

    getEntryType(entry) {
        if (angular.isDefined(entry)) {
            return entry.sys.contentType.sys.id;
        }
        return '';
    }

    getCurrentPageIndex() {
        if (angular.isUndefined(this.currentPageIndex)) {
            return;
        }
        return this.currentPageIndex;
    }

    getNumberOfPages() {
        if (this.contentfulHelper.hasRlos(this.module)) {
            return this.module.fields.rlos.length;
        }
    }

    navigateToSelectedEntry() {
        this.$state.go('contentPage', {programmeId: this.programmeId, contentId: this.selectedEntryId, optionSet: this.optionSet});
    }

    navigateToPreviousEntry() {
        if (this.previousNavigationTarget === 'programme') {
            this.$state.go('programmePage', {programmeId: this.previousEntryId, optionSet: this.optionSet});
        } else {
            this.$state.go('contentPage', {programmeId: this.programmeId, contentId: this.previousEntryId, optionSet: this.optionSet});
        }
    }

    navigateToNextEntry() {
        if (this.nextNavigationTarget === 'programme') {
            this.$state.go('programmePage', {programmeId: this.nextEntryId, optionSet: this.optionSet});
        } else {
            this.$state.go('contentPage', {programmeId: this.programmeId, contentId: this.nextEntryId, optionSet: this.optionSet});
        }
    }

    goBackToProgramme() {
        this.$state.go('programmePage', {programmeId: this.$scope.programmeId, optionSet: this.optionSet});
    }

    /**
     * In case the user navigate to a RLO using direct link
     */
    isSingleEntryView() {
        return !this.module;
    }
}