// ng-photoswipe is not importing photoswipe properly so we need manually add it to the window
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import photoswipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export function CmsPhotoSwipe($timeout) {
    'ngInject';

    let directive = {
        restrict: 'E',
        templateUrl: 'cmsPhotoSwipe/template.html',
        scope: {
            imageSrc: '@',
            imageAlt: '@',
        },
        controller: CmsPhotoSwipeController,
        controllerAs: 'ctrl',
        bindToController: true,
        link: function(scope, element) {
            $timeout(() => {
                // as part of accessibility improvements we need to modify content of PhotoSwipe window
                // we need to use $timeout in order to ensure children are initialized when searching for buttons
                const psButtons = element[0].querySelectorAll('.pswp__button');
                for (const psButton of psButtons) {
                    psButton.innerText = psButton.title;
                }
            });
        }
    };

    return directive;
}

class CmsPhotoSwipeController {
    constructor($window) {
        'ngInject';

        // ng-photoswipe is not importing photoswipe properly so we need manually add it to the window
        $window.PhotoSwipe = photoswipe;
        $window.PhotoSwipeUI_Default = PhotoSwipeUI_Default;

        this.opts = {index: 0, history: false, modal: true, maxSpreadZoom: 4, mouseUsed: true};
        this.isOpen = false;
    }

    $onInit() {
        this.imageAlt = this.imageAlt || '';
        this.slides = [{ src: this.imageSrc, title: this.imageAlt }];
    }

    onGalleryClose() {
        this.isOpen = false;
        angular.element(document.querySelector('#mdContentId'))
            .removeClass('photoswipe-open');
    }

    openImage() {
        //a class for hiding the top navigation menu
        angular.element(document.querySelector('#mdContentId'))
            .addClass('photoswipe-open');

        let image = document.getElementById('slideshowImage');
        let width = image.naturalWidth;
        let height = image.naturalHeight;
        this.slides[0].h = height;
        this.slides[0].w = width;
        this.isOpen = true;
    }
}
