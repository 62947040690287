import './quizPage.html';
import './quizPage.scss';
import shuffle from 'lodash.shuffle';

export class QuizPageController {
    constructor($state, $scope, contentUtilService, tracking, publicApiService, quizService) {
        'ngInject';

        this.$state = $state;
        this.$scope = $scope;
        this.contentUtilService = contentUtilService;
        this.tracking = tracking;
        this.publicApiService = publicApiService;
        this.quizService = quizService;

        contentUtilService.markAndTrustContentAsHtml($scope.content, 'introduction');

        this.selectedAnswers = [];
        if (angular.isArray($scope.content.questions)) {
            $scope.questions = $scope.content.questions.map((question, index) => {
                let result = {
                    text: question.fields.question,
                };

                let correctAnswers = this.mapAnswers(question.fields.correctAnswers, true);
                if (correctAnswers.length === 0) {
                    throw 'Correct answers of question "' + result.text + '" are empty!';
                }
                if (correctAnswers.length > 1) {
                    result.type = 'checkbox';
                    // Initialize array of answers
                    this.selectedAnswers[index] = [];
                } else {
                    result.type = 'radio';
                }

                result.answers = correctAnswers.concat(this.mapAnswers(question.fields.wrongAnswers, false));
                result.answers = shuffle(result.answers);

                return result;
            });
        }

        this.freeze = false;
    }

    mapAnswers(rawAnswersText, isCorrect) {
        if (!rawAnswersText) {
            return [];
        }
        let answers = rawAnswersText.split('\n');
        return answers.map(answerText => {
            return {
                text: this.contentUtilService.markAndTrustStringAsHtml(answerText),
                isCorrect: isCorrect,
            };
        });
    }

    hasEveryQuestionAnAnswer() {
        let questions = this.$scope.questions;
        for (let i = 0; i < questions.length; i++) {
            if (questions[i].type === 'checkbox') {
                // Check if any answer was given in the sub array
                if (!this.selectedAnswers[i].some(selected => selected)) {
                    return false;
                }
            } else if (!angular.isObject(this.selectedAnswers[i])) {
                return false;
            }
        }

        return true;
    }

    submit() {
        this.freeze = true;
        let questions = this.$scope.questions;
        let allAnswersCorrect = true;

        this.selectedAnswers.forEach((answer, index) => {
            let question = questions[index];
            if (question.type === 'checkbox') {
                // The answer is an array of boolean, positions that are 'true' are selected

                let allCorrectSelected = true;
                let anyFalseSelected = false;

                let selectedAnswers = answer;
                question.answers.forEach((answer, index) => {
                    let selected = selectedAnswers[index];
                    if (selected) {
                        if (!answer.isCorrect) {
                            allCorrectSelected = false;
                            anyFalseSelected = true;
                        }
                    } else if (answer.isCorrect) {
                        allCorrectSelected = false;
                    }
                });

                if (anyFalseSelected) {
                    question.answered = 'wrong';
                    allAnswersCorrect = false;
                } else if (allCorrectSelected) {
                    question.answered = 'correct';
                } else {
                    question.answered = 'partially-correct';
                    allAnswersCorrect = false;
                }
            } else {
                if (answer.isCorrect) {
                    question.answered = 'correct';
                } else {
                    question.answered = 'wrong';
                    allAnswersCorrect = false;
                }
            }
        });

        if (allAnswersCorrect) {
            this.tracking.trackAchievement(this.$scope.programmeId, this.$scope.contentId)
                .then(() => {
                    this.publicApiService.getActiveProgrammes(true)
                        .then(activeProgrammes => {
                            let programme = activeProgrammes.find(p => p.id === this.$scope.programmeId);
                            this.quizService.enrichProgrammeWithPatientProgrammeInformation(this.$scope.programme, programme);
                            this.canContinue = true;
                        });
                });
        } else {
            // There were errors - offer a retry
            this.canRetry = true;
        }
    }

    getQuestionClasses(question) {
        if (angular.isUndefined(question.answered)) {
            return '';
        }

        return ['answered', question.answered];
    }

    resetQuiz() {
        // Remove answers
        this.selectedAnswers = [];

        let questions = this.$scope.questions;
        questions.forEach((question, index) => {
            // Delete results
            delete question.answered;

            // Re-initialize sub arrays for multi answers
            if (question.type === 'checkbox') {
                this.selectedAnswers[index] = [];
            }

            // Re-shuffle answers
            question.answers = shuffle(question.answers);
        });

        this.freeze = false;
        this.canRetry = false;
    }
}