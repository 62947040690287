/**
 * Service containing utility methods to handle content from contentful.
 *
 * Created by raoulzander on 08.08.17.
 */

const DEFAULT_PROGRAMME_SETTINGS = {
    quizBlocksProgress: false,
};

/* global angular */
export class ContentfulHelperService {
    constructor($injector, $state, contentful, quizService) {
        'ngInject';

        this.$injector = $injector;
        this.$state = $state;
        this.contentful = contentful;
        this.quizService = quizService;

        this.managers = {
            'default': 'contentfulManager',
            'preview': 'contentfulPreviewManager',
        }
    }

    /**
     * Provides the correct contentful manager service for the provided optionSet.
     *
     * @param optionSet
     * @return {ContentfulManagerService | ContentfulPreviewManagerService}
     */
    getContentfulManager(optionSet) {
        if (!this.managers.hasOwnProperty(optionSet)) {
            this.$state.go('error', {statusCode: 404});
        }

        // Use $injector here to prevent a circular dependency between
        // ContentfulManagerServices and ContentfulHelperService

        // This method could also be part of yet another service, but for the
        // sake of compactness it's part of ContentfulHelperService, but uses
        // $injector.
        return this.$injector.get(this.managers[optionSet]);
    }

    /**
     * @return {Promise}
     * @private
     */
    loadProgramme(programmeId, optionSet, programmeInformation) {
        return this.contentful
            .entries('include=3&sys.id=' + programmeId, optionSet)
            .then(response => {
                let programme = response.data.items[0];
                this.mapProgramme(programme, programmeInformation);
                return programme;
            });
    }

    /**
     * @return {Promise}
     * @private
     */
    loadEntry(contentId, optionSet, includedLevels) {
        let include = '';
        if (includedLevels) {
            include = 'include=' + includedLevels + '&';
        }
        return this.contentful
            .entries(include + 'sys.id=' + contentId, optionSet)
            .then((response) => {
                let entry = response.data.items[0];
                entry = this._mapEntry(entry);
                return entry;
            });
    }

    /**
     * Find the requested module (with resolved links) within the programme
     */
    findModule(programme, moduleId) {
        if (!this.hasModules(programme)) {
            return undefined;
        }

        return programme.fields.modules.find(module => {
            return module.sys.id === moduleId;
        });
    }

    findContent(programme, contentId) {
        let result = {};

        if (!this.hasModules(programme)) {
            return result;
        }

        programme.fields.modules.some(module => {
            if (!this.hasRlos(module)) {
                return false;
            }

            let foundContent = module.fields.rlos.find(content => {
                return content.sys.id === contentId;
            });

            if (angular.isDefined(foundContent)) {
                result.module = module;
                result.entry = foundContent;
                return true;
            }

            return false;
        });

        return result;
    }

    // noinspection JSMethodCanBeStatic - Can not be static as it is used as service method
    /**
     * @return {boolean}
     */
    hasModules(programme) {
        return angular.isObject(programme) &&
            angular.isObject(programme.fields) &&
            angular.isArray(programme.fields.modules) &&
            programme.fields.modules.length > 0;
    }

    // noinspection JSMethodCanBeStatic - Can not be static as it is used as service method
    /**
     * @return {boolean}
     */
    hasRlos(module) {
        return angular.isObject(module) &&
            angular.isObject(module.fields) &&
            angular.isArray(module.fields.rlos) &&
            module.fields.rlos.length > 0;
    }

    getProgramCompletionPercentage(modules) {
        if (!angular.isArray(modules) || modules.length === 0 || !angular.isNumber(modules[0].completedModuleStepsCount)) {
            return 0;
        }
        let totalSteps = modules.reduce((count, module) => count += module.fields.rlos.length, 0);

        let completedSteps = modules.reduce((count, module) => count += module.completedModuleStepsCount, 0);
        return Math.round(completedSteps / totalSteps * 100);
    }

    /**
     * Takes a programme and finds titles that will be split into primary and secondary titles.
     */
    mapProgramme(programme, programmeInformation) {
        if (!angular.isObject(programme.fields)) {
            return;
        }

        // ensures all required settings by code are present
        programme.fields.settings = angular.merge({}, DEFAULT_PROGRAMME_SETTINGS, programme.fields.settings);
        /*
         * Check for modules and map them if existing
         */
        if (this.hasModules(programme)) {
            programme.fields.modules = programme.fields.modules.map(module => {
                return this._mapModule(module);
            });

            // Enriches the Contentful programme with the patient's programme information(progress, blocking quizzes etc)
            if (angular.isObject(programmeInformation)) {
                this.quizService.enrichProgrammeWithPatientProgrammeInformation(programme, programmeInformation);
            }
        }

        return programme;
    }

    /**
     * @private
     */
    _mapModule(module) {
        // A module is also a content
        module = this._mapContent(module);

        if (this.hasRlos(module)) {
            module.fields.rlos = module.fields.rlos.map(entry => {
                return this._mapContent(entry);
            });
        }

        return module;
    }

    /**
     * @private
     */
    _mapContent(entry) {
        if (!angular.isObject(entry.fields) ||
            angular.isUndefined(entry.fields.title)) {
            return entry;
        }

        let titleParts = entry.fields.title.split(':');
        if (titleParts.length > 1) {
            entry.fields.primaryTitle = titleParts[0] + ':';
            entry.fields.secondaryTitle = titleParts.slice(1).join(':');
        } else {
            entry.fields.primaryTitle = entry.fields.title;
        }

        return entry;
    }

    /**
     * @private
     */
    _mapEntry(entry) {
        switch (entry.sys.contentType.sys.id) {
            case 'programme':
                return this.mapProgramme(entry);
            case 'module':
                return this._mapModule(entry);
            default:
                return this._mapContent(entry);
        }
    }
}
