/**
 * Created by raoulzander on 12.07.17.
 */

const HB_BACKEND_GROUP_ID = 'hb';

export class ApiService {
    constructor($http, $httpParamSerializerJQLike) {
        'ngInject';

        this.$http = $http;
        this.$httpParamSerializerJQLike = $httpParamSerializerJQLike;
    }

    getPublicApiUrl(patientPublicIdentifier) {
        let bgId = this._deriveBackendGroupIdFrom(patientPublicIdentifier);
        return this.getPublicApiUrlForBG(bgId);
    }

    getPublicApiUrlForBG(bgId) {
        return '/api/bgid-' + bgId + '/rest/public/cms/';
    }

    postForm(req) {
        req = angular.extend({
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
        }, req);

        if (req.data) {
            Object.keys(req.data).forEach(key => {
                if (req.data[key] === null) {
                    delete req.data[key];
                }
            });
        }
        
        // serialize params
        req.data = this.$httpParamSerializerJQLike(req.data).replace(/%5B%5D/g, '');
        return this.$http(req);
    }

    /**
     * @private
     */
    _deriveBackendGroupIdFrom(patientPublicIdentifier) {
        if (!angular.isString(patientPublicIdentifier)) {
            return '';
        }

        patientPublicIdentifier = patientPublicIdentifier.replace(/\W/gm, ''); //remove all non-alphanumeric characters

        if (patientPublicIdentifier.length !== 8) {
            return '';
        }

        const firstBackendGroupInitial = patientPublicIdentifier.charAt(3);
        const lastBackendGroupInitial = patientPublicIdentifier.charAt(7);

        const backendGroupId = (firstBackendGroupInitial + lastBackendGroupInitial).toLowerCase();

        // Some of the patients inside HB backend group have digits on 4th and 8th char inside their PUBLIC_IDENTIFIER,
        // so for them we return HB backend group id
        if (this._isNumeric(backendGroupId)) {
            return HB_BACKEND_GROUP_ID;
        }

        return backendGroupId;
    }


    _isNumeric(value) {
        return /^\d+$/.test(value);
    }
}
