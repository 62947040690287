/**
 * Created by raoulzander on 19.06.17.
 */

export function translateConfig($translateProvider, $windowProvider) {
    'ngInject';

    const language = determineLanguage($translateProvider, $windowProvider);
    $windowProvider.$get().document.documentElement.lang = language;

    $translateProvider
        .useStaticFilesLoader({
            prefix: 'assets/i18n/translation-',
            suffix: '.json',
        })
        .useSanitizeValueStrategy('escape')
        .determinePreferredLanguage(() => determineLanguage($translateProvider, $windowProvider));
}

function determineLanguage($translateProvider, $window) {
    let preferredLanguage = 'en';

    let languageFromUrl = getLanguageFromURL($window);
    if (languageFromUrl) {
        return languageFromUrl
    }

    // Only use the language part of the browser locale
    let locale = $translateProvider.resolveClientLocale();
    if (!locale) {
        return preferredLanguage;
    }

    let determinedLanguage = locale.split('_')[0].toLowerCase();
    switch (determinedLanguage) {
        case 'en':
        case 'fr':
        case 'it':
        case 'de':
            preferredLanguage = determinedLanguage;
            break;
    }

    return preferredLanguage;
}

function getLanguageFromURL($windowProvider) {
    let location = $windowProvider.$get().location;
    let pageUrl = location.search ? location.search.substring(1) : location.hash.split('?')[1];
    let urlParameters = pageUrl ? pageUrl.split('&') : [];

    for (let p of urlParameters) {
        let [paramName, paramValue] = p.split('=');
        if (paramName === 'language') {
            return paramValue;
        }
    }
}

