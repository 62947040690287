/**
 * Created by raoulzander on 20.12.2017.
 */

export class EntryController {
    constructor($state, contentful, publicApiService) {
        'ngInject';

        this.$state = $state;

        let patientPublicId = $state.params.pubId;

        if (angular.isString(patientPublicId) && /(?:[a-zA-Z]{3}[0-9A-Za-z]){2}/.test(patientPublicId)) {
            publicApiService.setPatientIdentifier(patientPublicId);
            // Tracking will be activated automatically, because every display
            // page enables tracking if there is a patient public id available
        }

        let entryId = $state.params.entryId;
        let optionSet = $state.params.optionSet;

        contentful.entry(entryId, optionSet)
            .then(response => {
                let entry = response.data;
                let entryType = entry.sys.contentType.sys.id;

                let targetState;
                let parameterName;
                switch (entryType) {
                    case 'programme':
                    case 'module':
                        targetState = entryType + 'Page';
                        parameterName = entryType + 'Id';
                        break;
                    default:
                        targetState = 'contentPage';
                        parameterName = 'contentId';
                        break;
                }
                let parameters = {
                    optionSet: optionSet,
                    omitHierarchy: true,
                };
                parameters[parameterName] = entryId;
                $state.go(targetState, parameters, {location: false});
            });
    }
}