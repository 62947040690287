/**
 * Created by raoulzander on 02.06.17.
 */
import './headline.scss';

export function HeadlineDirective() {
    'ngInject';

    let directive = {
        restrict: 'E',
        templateUrl: 'headline/headline.html',
        scope: {
            programme: '<?',
        },
        controller: HeadlineDirectiveController,
        controllerAs: 'ctrl',
        bindToController: true,
    };

    return directive;
}

class HeadlineDirectiveController {
    constructor($stateParams) {
        'ngInject';
        this.$stateParams = $stateParams;
    }

    $onInit() {
        this.optionSet = this.$stateParams.optionSet;
        
        if (angular.isDefined(this.programme)) {
            this.homeSref = "programmePage({programmeId: this.programme.sys.id, optionSet: this.optionSet})";
        } else {
            this.homeSref = "landingPage";
        }
    }
}
