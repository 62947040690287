/* global angular */
export class QuizService {
    constructor() {
    }

    enrichProgrammeWithPatientProgrammeInformation(programme, patientProgrammeInformation) {
        this._lockModulesByProgress(programme, patientProgrammeInformation.progress);
        this._calculateCompletionAndAdjustLocksByQuizzes(programme, patientProgrammeInformation);
    }

    /**
     * @private
     */
    _lockModulesByProgress(programme, progress) {
        const progressValue = progress || 0;
        const progressToModulesUnlockMap = programme.fields.settings.progressToModulesUnlockMap;

        //purge the programme from last mappings
        programme.fields.modules.forEach(module => {
            delete module.isBlocked;
        });

        if (!angular.isObject(progressToModulesUnlockMap)) {
            return;
        }

        let unlockedModulesSet = this._getUnlockedModuleIdsSet(progressToModulesUnlockMap, progressValue);
        programme.fields.modules.forEach(module => {
            module.isBlocked = !unlockedModulesSet.has(module.sys.id);
        });
    }

    /**
     * @private
     */
    _getUnlockedModuleIdsSet(progressToModulesUnlockMap, progressValue) {
        return Object.keys(progressToModulesUnlockMap)
            .filter(key => key <= progressValue)
            .reduce((unlockedModules, key) => {
                progressToModulesUnlockMap[key].forEach(module => unlockedModules.add(module));
                return unlockedModules;
            }, new Set());
    }

    /**
     * @private
     */
    _calculateCompletionAndAdjustLocksByQuizzes(programme, patientProgrammeInformation) {
        const completedQuizIds = new Set(patientProgrammeInformation.achievementContentIds);
        const viewedContentIds = new Set(patientProgrammeInformation.viewedContentIds);
        const quizBlocksProgress = programme.fields.settings.quizBlocksProgress;

        let progressBlocked = false;

        programme.fields.modules.forEach(module => {

            module.isBlocked = progressBlocked || module.isBlocked;
            module.completedModuleStepsCount = 0;

            if (!QuizService._hasRlos(module)) {
                return;
            }

            module.fields.rlos.forEach(rlo => {
                rlo.isBlocked = progressBlocked;
                if (rlo.sys.contentType.sys.id === 'quiz') {
                    rlo.isCompleted = completedQuizIds.has(rlo.sys.id);
                    rlo.quizBlocksNextEntry = !rlo.isCompleted && quizBlocksProgress;
                    if (!rlo.isCompleted) {
                        progressBlocked = quizBlocksProgress;
                    }
                } else {
                    rlo.isCompleted = viewedContentIds.has(rlo.sys.id);
                }
            });

            module.completedModuleStepsCount = module.fields.rlos.reduce((count, rlo) => rlo.isCompleted ? count + 1 : count, 0);
        });
    }

    /**
     * @return {boolean}
     */
    static _hasRlos(module) {
        return angular.isObject(module) &&
            angular.isObject(module.fields) &&
            angular.isArray(module.fields.rlos) &&
            module.fields.rlos.length > 0;
    }
}