/**
 * Created by raoulzander on 09.06.17.
 */
export class TextPageController {
    constructor($scope, contentUtilService) {
        'ngInject';

        $scope.contentBody = $scope.content.body;

        contentUtilService.markAndTrustContentAsHtml($scope.content, 'keyPoints');
    }
}