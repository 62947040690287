/**
 * Service to manage access to contentful API.
 *
 * This service should be used if the CMS tries to render published
 * contents. It uses the (cookie stored) patient public identifier
 * to get the patient programme.
 *
 * All getters are cached by programme, meaning: as long as the
 * requested entry can be resolved in the currently loaded
 * programme, no new request will be send to the contentful API.
 *
 * Created by raoulzander on 02.06.17.
 */

/* global angular */
export class ContentfulManagerService {
    constructor($state, $q, contentful, publicApiService, contentfulHelper) {
        'ngInject';

        this.$state = $state;
        this.$q = $q;
        this.contentful = contentful;
        this.publicApiService = publicApiService;
        this.contentfulHelper = contentfulHelper;

        /**
         * Constant option set that's used for all requests by this service.
         * @type {string}
         */
        this.optionSet = 'default';

        /**
         * Will hold the promise that loads or loaded the
         * whole programme as soon as the service is used.
         *
         * @type {Promise}
         */
        this.programmePromises = [];
    }

    /**
     * @private
     * @return {boolean}
     */
    _isProgrammeLoaded(programmeId) {
        return angular.isDefined(this.programmePromises[programmeId]);
    }

    getProgramme(programmeId) {
        return this._getActiveProgrammes(true)
            .then(activeProgrammes => {
                let programme = activeProgrammes.find(p => p.id === programmeId);
                return this._loadProgramme(programme, true);
            });
    }

    getModule(programmeId, moduleId, omitHierarchy) {
        if (omitHierarchy) {
            return this.contentfulHelper.loadEntry(moduleId, this.optionSet)
                .then(entry => {
                    return {
                        module: entry,
                    }
                });
        }

        return this._getActiveProgrammes()
            .then(activeProgrammes => {
                let programme = activeProgrammes.find(p => p.id === programmeId);
                return this._loadProgramme(programme);
            })
            .then(programme => {
                let foundModule = this.contentfulHelper.findModule(programme, moduleId);

                if (angular.isDefined(foundModule)) {
                    return {
                        module: foundModule,
                        programme: programme,
                    }
                } else {
                    // Module was not found in the patient programme
                    this.$state.go('error', {statusCode: 400});
                    return this.$q.reject();
                }
            });
    }

    getContent(programmeId, contentId, omitHierarchy) {
        if (omitHierarchy) {
            return this.contentfulHelper.loadEntry(contentId, this.optionSet).then(entry => {
                return {
                    content: entry,
                }
            });
        }

        return this._getActiveProgrammes()
            .then(activeProgrammes => {
                let programme = activeProgrammes.find(p => p.id === programmeId);
                return this._loadProgramme(programme);
            })
            .then(programme => {
                let find = this.contentfulHelper.findContent(programme, contentId);
                if (angular.isDefined(find.entry)) {
                    return {
                        programme: programme,
                        module: find.module,
                        content: find.entry,
                    }
                } else {
                    // Content was not found in the patient programme
                    this.$state.go('error', {statusCode: 400});
                    return this.$q.reject();
                }
            });
    }

    _loadProgramme(programmeInformation, remap) {
        if (!this._isProgrammeLoaded(programmeInformation.id)) {
            this.programmePromises[programmeInformation.id] = this.contentfulHelper
                .loadProgramme(
                    programmeInformation.id,
                    this.optionSet,
                    programmeInformation);
        } else if (remap) {
            // remap to update the programmeInformation (viewedContent and achievements)
            this.programmePromises[programmeInformation.id] = this.programmePromises[programmeInformation.id].then(programme => {
                this.contentfulHelper.mapProgramme(programme, programmeInformation);
                return programme;
            });
        }
        return this.programmePromises[programmeInformation.id];
    }

    /**
     * @return {Promise.<number>}
     * @private
     */
    _getActiveProgrammes(forceReload) {
        return this.publicApiService.getActiveProgrammes(forceReload)
            .then(activeProgrammes => {
                if (!angular.isArray(activeProgrammes) || activeProgrammes.length === 0) {
                    this.$state.go('landingPage', {
                        patientPublicId: null,
                        message: 'Please enter your Oviva Short Code.',
                    });

                    return this.$q.reject();
                }

                return activeProgrammes;
            });
    }
}

