import { themeConfig } from './config/theme.config';
import { routerConfig } from './ui-routing';
import { contentfulConfigFactory } from './config/contentful.config';
import { angularMaterialConfig } from './config/angularMaterial.config';
import { ContentPageController } from './contentPage/contentPage.controller';
import { ProgrammeEntryDirective } from './overviewPage/programmeEntry/programmeEntry.directive';
import { OverviewPageController } from './overviewPage/overviewPage.controller';
import { ProgrammePageController } from './programmePage/programmePage.controller';
import { ProgrammeListingDirective } from './programmeListing/programmeListing.directive';
import { HeadlineDirective } from './headline/headline.directive';
import { ContentfulManagerService } from './services/contentfulManager.service';
import { AudioPageController } from './contentPage/audio/audioPage.controller';
import { TextPageController } from './contentPage/text/textPage.controller';
import { VideoPageController } from './contentPage/video/videoPage.controller';
import { ModulePageController } from './contentPage/module/modulePage.controller';
import { RecipePageController } from './contentPage/recipe/recipePage.controller';
import { translateConfig } from './config/translate.config';
import { LandingPageController } from './landingPage/landingPage.controller';
import { ApiService } from './services/api.service';
import { RecipeListingPageController } from './contentPage/recipeListing/recipeListingPage.controller';
import { markdownConfig } from './config/markdown.config';
import { TrackingService } from './services/tracking.service';
import { PublicApiService } from './services/publicApi.service';
import { renderConfig } from './config/render.constants';
import { EntryController } from './entry/entry.controller';
import { TextDirectionService } from './services/textDirection.service';
import { QuizPageController } from './contentPage/quiz/quizPage.controller';
import { ContentfulHelperService } from './services/contentfulHelper.service';
import { ContentfulPreviewManagerService } from './services/contentfulPreviewManager.service';
import { QuizService } from './services/quiz.service';
import { ContentUtilService } from './contentPage/contentUtil.service';
import { CmsPhotoSwipe } from './cmsPhotoSwipe/cmsPhotoSwipe.directive';
import { locationConfig } from './config/location.config';

/**
 * Bootstraps the AngularJS application
 * @param {Object?} options configures the AngularJS application
 * @param {ContentfulFactoryConfig?} options.contentful configuration regarding Contentful
 */
export function bootstrapAngular(options) {

    registerAngularModule(options);

    angular.element(document).ready(function () {
        angular.bootstrap(document, ["app"], {
            strictDi: true,
            ngCsp: "no-unsafe-eval"
        });
    });
}

/**
 * Registers the Angular module
 * @param {Object?} options configures the AngularJS application
 * @param {ContentfulFactoryConfig?} options.contentful configuration regarding Contentful
 */
function registerAngularModule(options) {

    options = options || {};
    const contentfulOptions = options.contentful || {};

    angular.module('app', [
            'ngAnimate',
            'ngCookies',
            'ngMaterial',
            'ngPhotoswipe',
            'ui.router',
            'contentful',
            'hc.marked',
            'pascalprecht.translate',
            'cleave.js',
        ])
        .config(angularMaterialConfig)
        .config(themeConfig)
        .config(routerConfig)
        .config(contentfulConfigFactory(contentfulOptions))
        .config(translateConfig)
        .config(markdownConfig)
        .config(locationConfig)

        .constant('renderConfig', renderConfig)

        .service('apiService', ApiService)
        .service('contentfulHelper', ContentfulHelperService)
        .service('contentfulManager', ContentfulManagerService)
        .service('contentfulPreviewManager', ContentfulPreviewManagerService)
        .service('contentUtilService', ContentUtilService)
        .service('publicApiService', PublicApiService)
        .service('quizService', QuizService)
        .service('textDirection', TextDirectionService)
        .service('tracking', TrackingService)

        .directive('programmeListing', ProgrammeListingDirective)
        .directive('headline', HeadlineDirective)
        .directive('cmsPhotoSwipe', CmsPhotoSwipe)
        .directive('programmeEntry', ProgrammeEntryDirective)

        .controller('AudioPageController', AudioPageController)
        .controller('ContentPageController', ContentPageController)
        .controller('OverviewPageController', OverviewPageController)
        .controller('EntryController', EntryController)
        .controller('LandingPageController', LandingPageController)
        .controller('ModulePageController', ModulePageController)
        .controller('ProgrammePageController', ProgrammePageController)
        .controller('QuizPageController', QuizPageController)
        .controller('RecipePageController', RecipePageController)
        .controller('RecipeListingPageController', RecipeListingPageController)
        .controller('TextPageController', TextPageController)
        .controller('VideoPageController', VideoPageController);
}
