import './recipePage.scss';

export class RecipePageController {
    constructor($scope, $translate, contentUtilService) {
        'ngInject';

        this.$translate = $translate;

        contentUtilService.markAndTrustContentAsHtml($scope.content, 'ingredients');
        contentUtilService.markAndTrustContentAsHtml($scope.content, 'comment');
        contentUtilService.markAndTrustContentAsHtml($scope.content, 'body');

        let methodValue = $scope.content.method;
        if (angular.isString(contentUtilService)) {
            $scope.content.method = methodValue.split('\n');
        }

        contentUtilService.markAndTrustContentAsHtml($scope.content, 'method');
    }

    booleanToString(bool) {
        if (bool) {
            return this.$translate.instant('contentPage.recipe.yes');
        } else {
            return this.$translate.instant('contentPage.recipe.no');
        }
    }
}