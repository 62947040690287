const trackingIntervalSec = 10;

/**
 * Created by raoulzander on 20.07.17.
 */
export class TrackingService {
    constructor($stateParams, $interval, $q, $transitions, publicApiService, apiService) {
        'ngInject';

        this.$stateParams = $stateParams;
        this.$interval = $interval;
        this.$q = $q;
        this.$transitions = $transitions;

        this.publicApiService = publicApiService;
        this.apiService = apiService;
    }

    /**
     * Tracks time for the provided content id as long as the provided scope remains.
     * @param $scope
     * @param contentId
     * @param programmeId
     */
    trackView($scope, contentId, programmeId) {
        // Only track views if the optionSet is default and we use the public identifier
        if (this.$stateParams.optionSet !== 'default') {
            return;
        }

        this._startTracking(contentId, programmeId);

        const cancelTransitionCallback = this.$transitions.onBefore({}, transition => {
            cancelTransitionCallback();
            let trackPromise = this._endTracking();
            if (transition.$to().name === 'programmePage') { //if transition is to programme page wait for time tracking to finish
                return trackPromise
                    .catch(() => { //if tracking fails we still transition forward
                        return this.$q.resolve();
                    });
            }

            return true;
        });
    }

    trackAchievement(programmeId, achievementContentId) {
        // Only track achievements if the optionSet is default and we use the public identifier
        if (this.$stateParams.optionSet !== 'default') {
            return;
        }

        let { patientPublicId, patientUuid, bg } = this.publicApiService.getPatientIdentifier();

        if (patientPublicId === null && (patientUuid === null || bg === null)) {
            return;
        }

        return this.apiService.postForm({
            url: this.publicApiService.getPublicApiUrlForTracking() + 'achievement.json',
            data: {
                programmeContentId: programmeId,
                achievementContentId: achievementContentId,
                patientPublicIdentifier: patientPublicId,
                patientUserUuid: patientUuid
            }
        });
    }

    /**
     * Starts time tracking of the provided content id.
     * @param contentId
     * @param programmeId
     *
     * @private
     */
    _startTracking(contentId, programmeId) {
        let { patientPublicId, patientUuid, bg } = this.publicApiService.getPatientIdentifier();

        if (patientPublicId === null && (patientUuid === null || bg === null)) {
            return;
        }

        this.currentTrackingParameter = {
            publicPatientIdentifier: patientPublicId,
            contentId: contentId,
            programmeContentId: programmeId,
            patientUserUuid: patientUuid
        };
        this.lastTrackingTime = Date.now();
        this.timeoutPromise = this.$interval(() => {
                this._trackContentConsumption(this.currentTrackingParameter, trackingIntervalSec);
                this.lastTrackingTime = Date.now();
            },
            trackingIntervalSec * 1000,
            0,
            false);
    }

    /**
     * Stops the current content tracking.
     * @private
     */
    _endTracking() {
        if (angular.isDefined(this.timeoutPromise)) {
            let timeElapsed = (Date.now() - this.lastTrackingTime) / 1000;
            this.$interval.cancel(this.timeoutPromise);
            return this._trackContentConsumption(this.currentTrackingParameter, timeElapsed);
        }
        return this.$q.resolve();
    }

    /**
     * @private
     */
    _trackContentConsumption(trackingParameter, timeElapsed) {
        timeElapsed = Math.round(timeElapsed);
        if (timeElapsed < 1) {
            // Backend will reject less than 1 second trackings
            return this.$q.resolve();
        }
        this.lastTrackingTime = Date.now();
        let data = angular.merge({}, trackingParameter, {timeElapsed: timeElapsed});

        return this.apiService.postForm({
            url: this.publicApiService.getPublicApiUrlForTracking() + 'track-view.json',
            data: data,
        });
    }
}
