import './vendor';
import './index.scss';
import './assets/i18n/i18n.csv'
import {bootstrapAngular} from './index.module';

import './angular-photoswipe/angular-photoswipe';
import './angular-photoswipe/views/ng-photoswipe.html';

import "@fontsource/inter";
import "@fontsource/dm-serif-text";

/**
 * Gathers all necessary configuration and bootstraps the application.
 * @return {Promise<void>}
 */
function bootstrapApplication() {
    return fetchContentfulConfig()
        .then(contentfulConfig => {
            return {contentful: contentfulConfig}
        })
        .then(bootstrapAngular);
}

/**
 * Fetches the Contentful config for the current system deployment.
 * @return {Promise<Object>} the config for Contentful
 */
function fetchContentfulConfig() {
    return fetch('/api/rest/public/cms/contentful.json')
        .then(response => response.json())
        .then(mapContentfulConfig);
}

/**
 * Maps the API response to the config model expected by our app
 * @param {Object} contentfulConfigDto the API response
 * @return {Object} config for Contentful
 */
function mapContentfulConfig(contentfulConfigDto) {
    const config = {
        "default": {
            space: contentfulConfigDto.space,
            accessToken: contentfulConfigDto.accessToken,
        },
        "preview": {
            space: contentfulConfigDto.space,
            accessToken: contentfulConfigDto.previewAccessToken,
        },
    };

    if (contentfulConfigDto.defaultHost) {
        config.default.host = contentfulConfigDto.defaultHost;
    }

    if (contentfulConfigDto.previewHost) {
        config.preview.host = contentfulConfigDto.previewHost;
    }
    return config;
}

// boot the application, off we go!
bootstrapApplication();
