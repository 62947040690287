/**
 * Created by raoulzander on 05.07.17.
 */
import './landingPage.scss';

export class LandingPageController {
    constructor($scope, $state, publicApiService) {
        'ngInject';

        this.$state = $state;

        this.publicApiService = publicApiService;

        this.cleaveOptions = {
            blocks: [4, 4],
            delimiter: ' ‒ ', // Delimiter is a figure dash,
            uppercase: true,
        };

        this.loaded = false;
        this.loading = false;
        this.patientPublicId = '';
        this.patientPublicIdInvalid = false;
        this.hasRequestError = false;
        this.noProgrammesAssigned = false;
        this.patientId = '';

        $scope.$watch(() => {
            return this.patientPublicId;
        }, (newValue, oldValue) => {
            if (newValue !== oldValue && newValue.length === 8) {
                if (/(?:[a-zA-Z]{3}[0-9A-Za-z]){2}/.test(newValue)) {
                    this.forwardToNextPage(newValue);
                } else {
                    this.patientPublicIdInvalid = true;
                }
                return;
            }
            this.patientPublicIdInvalid = false;
            this.hasRequestError = false;
            this.noProgrammesAssigned = false;
        });

        if ($state.params.patientPublicId) {
            this.patientPublicId = $state.params.patientPublicId;
            this.forwardToNextPage(this.patientPublicId, true);
        } else if(this.publicApiService.isUsingPatientDetails()) {
            this.forwardToNextPage(null, true);
        } else {
            this.loaded = true;
        }


    }

    forwardToNextPage(patientPublicId, replaceInHistory) {
        this.loading = true;

        this.publicApiService.setPatientIdentifier(patientPublicId);

        // Use getActiveProgrammes to validate patientPublicId
        return this.publicApiService.getActiveProgrammes(true)
            .then(activeProgrammes => {
                let location = replaceInHistory ? 'replace' : true;
                this.loading = false;

                if (activeProgrammes.length === 0) {
                    this.noProgrammesAssigned = true;
                } else if (activeProgrammes.length === 1) {
                    return this.$state.go('programmePage', {programmeId: activeProgrammes[0].id}, {location: location});
                } else {
                    return this.$state.go('overviewPage', {}, {location: location});
                }
            })
            .catch(() => {
                this.hasRequestError = true;
                this.loading = false;
            });
    }


}