/**
 * Created by raoulzander on 01.06.17.
 */
import './programmeListing.scss';

export function ProgrammeListingDirective() {
    'ngInject';

    let directive = {
        restrict: 'E',
        templateUrl: 'programmeListing/programmeListing.html',
        scope: {
            programme: '<',
            selectedModuleId: '<?',
            selectedContentId: '<?',
        },
        controller: ProgrammeListingController,
        controllerAs: 'ctrl',
        bindToController: true,
    };

    return directive;
}

class ProgrammeListingController {
    constructor($stateParams) {
        'ngInject';

        this.optionSet = $stateParams.optionSet;
    }

    isModuleSelected(module) {
        if (angular.isUndefined(this.selectedModuleId)) {
            return false;
        }

        return module.sys.id === this.selectedModuleId;
    }

    isContentSelected(content) {
        if (angular.isUndefined(this.selectedContentId)) {
            return false;
        }

        return content.sys.id === this.selectedContentId;
    }
}