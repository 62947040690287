/**
 * Created by raoulzander on 12.06.17.
 */
export class VideoPageController {
    constructor($scope, contentUtilService) {
        'ngInject';

        contentUtilService.markAndTrustContentAsHtml($scope.content, 'introduction');
        contentUtilService.markAndTrustContentAsHtml($scope.content, 'body');
        contentUtilService.markAndTrustContentAsHtml($scope.content, 'keyPoints');
        contentUtilService.markAndTrustContentAsHtml($scope.content, 'transcript');


        this.isVideoLoaded = false;

        let videoElement = angular.element(document.querySelector('#video'))[0];
        videoElement.onloadedmetadata = () => {
            this.isVideoLoaded = true;
            $scope.$digest();
        };
    }
}