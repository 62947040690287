/**
 * Created by raoulzander on 01.06.17.
 */
import './programmePage.scss';

export class ProgrammePageController {

    constructor($window, $scope, $stateParams, $mdDialog, $sce, $state, marked, contentfulHelper, tracking, textDirection, publicApiService) {
        'ngInject';

        this.$window = $window;
        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.programmeLoaded = false;
        this.optionSet = $stateParams.optionSet;
        this.programmeId = $stateParams.programmeId;
        this.contentfulHelper = contentfulHelper;
        this.publicApiService = publicApiService;

        if(this.publicApiService.isUsingPatientDetails()) {
            publicApiService.setPatientIdentifier(null);
        }

        // Check if back button needs to be displayed
        this.publicApiService.getActiveProgrammes(false)
            .then(activeProgrammes => {
                this.showBackButton = activeProgrammes.length > 1;
            });

        let contentfulManager = contentfulHelper.getContentfulManager(this.optionSet);

        contentfulManager.getProgramme(this.programmeId)
            .then(programme => {
                this.programme = programme;
                this.programmeLoaded = true;

                if (angular.isObject(this.programme.fields)) {
                    this.modules = this.programme.fields.modules;

                    if (angular.isUndefined(this.programme.fields.bodyIsParsed) &&
                        angular.isDefined(this.programme.fields.body)) {
                        this.programme.fields.body = $sce.trustAsHtml(marked(this.programme.fields.body));
                        this.programme.fields.bodyIsParsed = true;
                    }

                    textDirection.adjustLayout(this.programme.fields.title);
                }

                tracking.trackView($scope, this.programmeId, this.programmeId);
            });
    }

    showHelp($event) {
        this.$mdDialog.show({
            templateUrl: 'programmePage/helpDialog.html',
            targetEvent: $event,
            clickOutsideToClose: true,
            controller: HelpDialogController,
            controllerAs: 'ctrl',
            locals: {
                // fields.helpPage will always be defined as this click is only available if its defined (see HTML)
                helpEntry: this.programme.fields.helpPage,
            },
            bindToController: true,
            fullscreen: true,
        })
    }

    goToModule(module) {
        this.$state.go('modulePage', {programmeId: this.programmeId, moduleId: module.sys.id, optionSet: this.optionSet});
    }

    getModuleBanner(module) {
        if (module.isBlocked) {
            return 'assets/img/lock-module-icon.svg';
        }
        if (module.fields.bannerImage) {
            return module.fields.bannerImage.fields.file.url;
        }
        return 'assets/img/default-module-icon.svg';
    }

    getCompletedStepsLabel(module) {
        // in case of '/preview' url postfix the count is not calculated hence using 0 as default
        let numberOfCompletedSteps = module.completedModuleStepsCount ? module.completedModuleStepsCount : 0;
        return numberOfCompletedSteps + '/' + module.fields.rlos.length;
    }

    isModuleComplete(module) {
        return module.completedModuleStepsCount === module.fields.rlos.length;
    }

    getProgramCompletionPercentage() {
        return this.contentfulHelper.getProgramCompletionPercentage(this.modules);
    }

    goBackToOverview() {
        return this.$state.go('overviewPage');
    }

}

class HelpDialogController {
    constructor($scope, $mdDialog, $sce, marked) {
        'ngInject';

        $scope.helpEntry = this.helpEntry.fields;

        if (angular.isUndefined($scope.helpEntry.bodyIsParsed)) {
            $scope.helpEntry.body = $sce.trustAsHtml(marked($scope.helpEntry.body));
            $scope.helpEntry.bodyIsParsed = true;
        }

        $scope.close = () => {
            $mdDialog.hide();
        }
    }
}