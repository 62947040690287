/**
 * @typedef SpaceConfig
 * @property {string?} host
 * @property {Boolean?} secure
 * @property {string} space
 */

/**
 * @typedef ContentfulFactoryConfig
 * @property {SpaceConfig} default
 * @property {SpaceConfig} preview
 */

/**
 * Constructs the AngularJS config function for Contentful
 * @param {ContentfulFactoryConfig} contentfulOptions
 * @return {function} AngularJS config function
 */
export function contentfulConfigFactory(contentfulOptions) {

    return function contentfulConfig(contentfulProvider) {
        'ngInject';

        const defaultOptions = {
            host: 'cdn.contentful.com',
            secure: true,
        };

        const previewDefaultOptions = {
            host: 'preview.contentful.com',
            secure: true,
        };

        const defaultSpaceOptions = angular.extend({}, defaultOptions, contentfulOptions['default'] || {});
        const previewSpaceOptions = angular.extend({}, previewDefaultOptions, contentfulOptions['preview'] || {});

        contentfulProvider.setOptions({
            'default': defaultSpaceOptions,
            'preview': previewSpaceOptions,
        });
    }
}
