export class ContentUtilService {
    constructor($sce, marked) {
        'ngInject';
        this.$sce = $sce;
        this.marked = marked;
    }

    markAndTrustContentAsHtml(contentParent, contentName) {

        const isContentParsedAttrName = 'is' + contentName + 'AlreadyParsed';
        let contentValue = contentParent[contentName];

        if (contentParent[isContentParsedAttrName] || angular.isUndefined(contentValue)) {
            return;
        }

        if (angular.isString(contentValue)) {
            contentParent[contentName] = this.markAndTrustStringAsHtml(contentValue);
            contentParent[isContentParsedAttrName] = true;
        } else if (angular.isArray(contentValue)) {
            contentParent[contentName] = contentValue.map(val => this.markAndTrustStringAsHtml(val));
            contentParent[isContentParsedAttrName] = true;
        }
    }

    markAndTrustStringAsHtml(content) {
        return this.$sce.trustAsHtml(this.marked(content));
    }
}