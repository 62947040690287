/* global angular */

const regex1 = /^[\x00- !-@[-`{-\u00bf\u00d7\u00f7\u02b9-\u02ff\u2000-\u2bff]*(?:\d[\x00- !-@[-`{-\u00bf\u00d7\u00f7\u02b9-\u02ff\u2000-\u2bff]*$|[A-Za-z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u02b8\u0300-\u0590\u0800-\u1fff\u2c00-\ufb1c\ufdfe-\ufe6f\ufefd-\uffff])/;
const regex2 = /^[\x00- !-@[-`{-\u00bf\u00d7\u00f7\u02b9-\u02ff\u2000-\u2bff]*$/;

export class TextDirectionService {
    constructor() {
        "ngInject";
    }

    adjustLayout(testString) {
        const direction = this.getDirection(testString);
        document.documentElement.setAttribute('dir', direction);
        return direction;
    }

    /**
     * Uses google's regex to detect if a string is considered rtl or ltr.
     *
     * @param testString
     * @param defaultDirection
     * @returns {string} 'rtl' or 'ltr'
     */
    getDirection(testString, defaultDirection) {
        let direction = defaultDirection || 'ltr';
        if (regex1.test(testString)) {
            direction = 'ltr'
        } else {
            if (!regex2.test(testString)) {
                direction = 'rtl'
            }
        }
        return direction
    }
}