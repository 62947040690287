import './overviewPage.scss';

export class OverviewPageController {
    constructor($scope, $state, publicApiService) {
        'ngInject';

        this.$state = $state;

        this.publicApiService = publicApiService;

        this.loaded = false;
        this.loading = false;
        this.patientPublicId = '';
        this.patientPublicIdInvalid = false;
        this.hasRequestError = false;

        this.optionSet = $state.params.optionSet;

        const { patientPublicId, patientUuid, bg } = this.publicApiService.getPatientIdentifier();
        if (angular.isString(patientPublicId) || (angular.isString(patientUuid) && angular.isString(bg))) {
            this.loadActiveProgrammes(false);
        }
    }

    loadActiveProgrammes(forceReload) {
        this.loading = true;

        this.publicApiService.getActiveProgrammes(forceReload)
            .then(activeProgrammes => {
                this.activeProgrammes = activeProgrammes;
                this.loading = false;
                this.loaded = true;
            })
            .catch(() => {
                this.hasRequestError = true;
                this.loading = false;
            });
    }

}