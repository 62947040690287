/**
 * Service to manage access to contentful API.
 *
 * This service should be used if the CMS tries to render a Preview
 * (instead of the published contents). It completely ignores the
 * patient public identifier but instead tries to reverse lookup
 * contents and modules to find matching parents.
 * This is useful to provide the previewed content with context
 * and an actual navigation.
 *
 * All getters are cached by programme, meaning: as long as the
 * requested entry can be resolved in the currently loaded
 * programme, no new request will be send to the contentful API.
 *
 * Created by raoulzander on 08.08.17.
 */

/* global angular */
export class ContentfulPreviewManagerService {
    constructor($state, $q, contentful, contentfulHelper) {
        'ngInject';

        this.$state = $state;
        this.contentful = contentful;
        this.contentfulHelper = contentfulHelper;

        /**
         * Constant option set that's used for all requests by this service.
         * @type {string}
         */
        this.optionSet = 'preview';

        /**
         * Will hold the promise that loads or loaded the
         * whole programme as soon as the service is used.
         *
         * @type {Promise}
         */
        this.programmePromises = [];
    }

    /**
     * @private
     * @return {boolean}
     */
    _isProgrammeLoaded(programmeId) {
        return angular.isDefined(this.programmePromises[programmeId]);
    }

    getProgramme(programmeId) {
        if (!this._isProgrammeLoaded(programmeId)) {
            this.programmePromises[programmeId] = this.contentfulHelper.loadProgramme(programmeId, this.optionSet);
        }

        return this.programmePromises[programmeId];
    }

    getModule(programmeId, moduleId, omitHierarchy) {
        if (omitHierarchy) {
            return this.contentfulHelper
                .loadEntry(moduleId, this.optionSet)
                .then(entry => {
                    return {
                        module: entry,
                    }
                });
        }

        return this._loadProgrammeByModuleId(programmeId, moduleId)
            .then(programme => {
                return {
                    module: this.contentfulHelper.findModule(programme, moduleId),
                    programme: programme,
                }
            });
    }


    /**
     *
     * @param moduleId
     * @return {Promise}
     * @private
     */
    _loadProgrammeByModuleId(programmeId, moduleId) {
        if (this._isProgrammeLoaded(programmeId)) {
            return this.programmePromises[programmeId];
        }

        this.programmePromises[programmeId] = this.contentful
            .processResponseWithMultipleEntries(
                this.contentful.request(
                    '/entries', {
                        params: {
                            'content_type': 'programme',
                            'include': 3, // resolve the complete tree down to content pages
                            'fields.modules.sys.id': moduleId, // Only return programme entries that contain the currently displayed entry
                            'limit': 1, // Should always be only 1 parent in a hierarchical tree
                        },
                    }, this.optionSet))
            .then(response => {
                // Return programme
                let programme = response.data.items[0];
                programme = this.contentfulHelper.mapProgramme(programme);
                return programme;
            });

        return this.programmePromises[programmeId];
    }

    getContent(programmeId, contentId, omitHierarchy) {
        if (omitHierarchy) {
            return this.contentfulHelper
                .loadEntry(contentId, this.optionSet)
                .then(entry => {
                    return {
                        content: entry,
                    }
                });
        }

        return this._loadProgrammeByContentId(programmeId, contentId)
            .then(programme => {
                let find = this.contentfulHelper.findContent(programme, contentId);

                return {
                    programme: programme,
                    module: find.module,
                    content: find.entry,
                }
            });
    }

    /**
     *
     * @param contentId
     * @return {Promise}
     * @private
     */
    _loadProgrammeByContentId(programmeId, contentId) {
        if (this._isProgrammeLoaded(programmeId)) {
            return this.programmePromises[programmeId].then(programme => {
                let find = this.contentfulHelper.findContent(programme, contentId);
                if (angular.isDefined(find.entry)) {
                    // Content was found and we can just return it
                    return programme;
                } else {
                    // Content was not found in the current programme.
                    // Discard current programme ...
                    this.programmePromises[programmeId] = undefined;
                    // ... and try to freshly load the correct programme
                    return this._loadProgrammeViaParentModule(programmeId, contentId);
                }
            });
        }

        // This request is not behind the programmePromise, as it
        // only loads the parent module, not the whole programme
        return this._loadProgrammeViaParentModule(programmeId, contentId);
    }

    /**
     * @return {Promise}
     * @private
     */
    _loadProgrammeViaParentModule(programmeId, contentId) {
        return this.contentful.request(
            '/entries',
            {
                params: {
                    'content_type': 'module', // search for parent module
                    'include': 0, // don't resolve any linked entries
                    'fields.rlos.sys.id': contentId, // Only return module entries that contain the currently displayed entry
                    'limit': 1, // Should always be only 1 parent in a hierarchical tree
                },
            },
            this.optionSet)
            .then(response => {
                if (response.data.items.length === 0) {
                    this.$state.go('error', {
                        statusCode: 404,
                        message: 'No module for content with id \'' + contentId + '\' found.',
                    });
                    return;
                }
                let moduleId = response.data.items[0].sys.id;
                return this._loadProgrammeByModuleId(programmeId, moduleId);
            });
    }
}