export function routerConfig($stateProvider, $urlRouterProvider) {
    'ngInject';

    $stateProvider
        .state('landingPage', {
            url: '/{patientPublicId:(?:(?:[a-zA-Z]{3}[0-9A-Za-z]){2})?}',
            controller: 'LandingPageController as ctrl',
            templateUrl: 'landingPage/landingPage.html',
        })

        .state('landingPagePatient', {
            url: '/?:patient_id&:bg',
            controller: 'LandingPageController as ctrl',
            templateUrl: 'landingPage/landingPage.html',
        })

        .state('overviewPage', {
            url: '/overview',
            controller: 'OverviewPageController as ctrl',
            params: {
                optionSet: {
                    value: 'default',
                    squash: true,
                },
            },
            templateUrl: 'overviewPage/overviewPage.html',
        })

        .state('programmePage', {
            url: '/programme/{programmeId}/{optionSet}',
            params: {
                optionSet: {
                    value: 'default',
                    squash: true,
                },
            },
            controller: 'ProgrammePageController as ctrl',
            templateUrl: 'programmePage/programmePage.html',
        })

        .state('programmePagePatient', {
            url: '/programme/{programmeId}/{optionSet}/?:patient_id&:bg',
            params: {
                optionSet: {
                    value: 'default',
                    squash: true,
                },
            },
            controller: 'ProgrammePageController as ctrl',
            templateUrl: 'programmePage/programmePage.html',
        })


        /*
         * Modules are a bit weird: they have a special logic but actually use the same markup as a content page.
         * The solution here is to handle the logic & main markup in ContentPageController and according template -
         * but use a custom nested state to show the contents of the module.
         *
         * In the future, if there are more pages the architecture should be reworked to have multiple page types
         * that can be transparently used.
         */
        .state('modulePage', {
            url: '/programme/{programmeId}/module/{moduleId}/{optionSet}',
            params: {
                optionSet: {
                    value: 'default',
                    squash: true,
                },
                omitHierarchy: false,
            },
            controller: 'ContentPageController as ctrl',
            templateUrl: 'contentPage/contentPage.html',
        })
        .state('modulePage.module', {
            controller: 'ModulePageController as ctrl',
            templateUrl: 'contentPage/module/modulePage.html',
        })

        .state('contentPage', {
            url: '/programme/{programmeId}/content/{contentId}/{optionSet}',
            params: {
                optionSet: {
                    value: 'default',
                    squash: true,
                },
                omitHierarchy: false,
                programmeId: "", // Default value when not provided
            },
            controller: 'ContentPageController as ctrl',
            templateUrl: 'contentPage/contentPage.html',
        })
        .state('contentPagePatient', {
            url: '/programme/{programmeId}/content/{contentId}/{optionSet}/?:patient_id&:bg',
            params: {
                optionSet: {
                    value: 'default',
                    squash: true,
                },
                omitHierarchy: false,
                programmeId: "", // Default value when not provided
            },
            controller: 'ContentPageController as ctrl',
            templateUrl: 'contentPage/contentPage.html',
        })
        .state('contentPage.audio', {
            controller: 'AudioPageController as ctrl',
            templateUrl: 'contentPage/audio/audioPage.html',
        })
        .state('contentPage.recipe', {
            controller: 'RecipePageController as ctrl',
            templateUrl: 'contentPage/recipe/recipePage.html',
        })
        .state('contentPage.text', {
            controller: 'TextPageController as ctrl',
            templateUrl: 'contentPage/text/textPage.html',
        })
        .state('contentPage.video', {
            controller: 'VideoPageController as ctrl',
            templateUrl: 'contentPage/video/videoPage.html',
        })
        .state('contentPage.recipeListing', {
            controller: 'RecipeListingPageController as ctrl',
            templateUrl: 'contentPage/recipeListing/recipeListingPage.html',
        })
        .state('contentPage.quiz', {
            controller: 'QuizPageController as ctrl',
            templateUrl: 'contentPage/quiz/quizPage.html',
        })

        .state('entry', {
            url: '/entry/{entryId}/{optionSet}?pubId',
            controller: 'EntryController as ctrl',
            params: {
                optionSet: {
                    value: 'default',
                    squash: true,
                },
            },
        })

        .state('error', {
            url: '/error/{statusCode}',
            templateUrl: 'error/404.html',
        });

    $urlRouterProvider.otherwise('/error/404');

    // Redirect to index page
    $urlRouterProvider.when('', '/');
}
