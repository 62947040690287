export function themeConfig($mdThemingProvider) {
    'ngInject';

    $mdThemingProvider.definePalette('oviva-green',
        $mdThemingProvider.extendPalette('teal', {
            '50': 'E0F6F2',
            '100': 'B5E9DF',
            '200': '88DBCB',
            '300': '57CCB6',
            '400': '34C2A7',
            '500': '10b797',
            '600': '0aA689',
            '700': '05957B',
            '800': '00826B',
            '900': '005F4F',
        }),
    );

    //override the default grey color of the 'grey' palette
    let backgroundPalette = $mdThemingProvider.extendPalette('grey', {
        '50': '#f6fafb',
    });
    $mdThemingProvider.definePalette('ovivaBackgroundPalette', backgroundPalette);

    $mdThemingProvider.theme('default')
        .primaryPalette('oviva-green')
        .accentPalette('amber')
        .warnPalette('red')
        .backgroundPalette('ovivaBackgroundPalette');
}